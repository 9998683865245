const buildCreateDynamoData = (params) => {
  return {
    "TableName": "works",
    "Item": {
      "title": {"S": params.data.title},
      "summary": {"S": params.data.summary},
      "link": {"S": params.data.link},
      "created_at": {"N": `${+ new Date()}`},
      "generic_partition_key": {"S": "Work"}
    }
  }
};

const createGetListString = (params) => {
  return `/read?table=works&page=${params.pagination.page}&perPage=${params.pagination.perPage}&type=getListPaginate&indexName=WorkGsi&partitionKey=generic_partition_key&paritionKeyType=S&partitionValue=Work&scanIndexForward=false&sortField=${params.sort.field}&sortFieldKey=N&sortOrder=${params.sort.order}&filterValue=${params.filter.title}`;
};

const createGetOneString = (params) => {
  return `/read?table=works&page=1&perPage=1&type=getListPaginate&partitionKey=id&paritionKeyType=S&partitionValue=${params.id}&scanIndexForward=false`;
};

const buildUpdateDynamoData = (params) => {
  return {
    "TableName": "works",
    "Key": {
      "id":  {"S": params.id},
      "created_at": {"N": params.data.created_at_unix}
    },
    "UpdateExpression": "set title = :title, #summary = :summary, #link = :link",
    "ExpressionAttributeNames": {
      "#summary": "summary",
      "#link": "link"
    },
    "ExpressionAttributeValues": {
      ":title": {"S": params.data.title},
      ":summary": {"S": params.data.summary},
      ":link": {"S": params.data.link},
    }
  }
};

const buildDeleteDynamoData = (params) => {
  return {
    "TableName": "works",
    "Key": {
      "id":  {"S": params.id},
      "created_at": {"N": params.previousData.created_at_unix}
    }
  }
};


const formatGetListResponse = (respData) => {
  let formattedResults = [];

  respData.results.forEach(result => {
    formattedResults.push({
      summary: result.summary['S'],
      created_at: new Date(parseInt(result.created_at['N'])).toLocaleString(),
      created_at_unix: result.created_at['N'],
      title: result.title['S'],
      link: result.link['S'],
      id: result.id['S']
    })
  });

  return {
    data: formattedResults,
    total: respData.totalItems
  }
};


const formatCreateResponse = (respData) => {
  return {
    "data": {
      "summary": respData.summary["S"],
      "link": respData.link["S"],
      "created_at": respData.created_at["N"],
      "id": respData.id["S"],
      "title": respData.title["S"]
    }
  }
};

module.exports = {
  formatGetListResponse,
  buildCreateDynamoData,
  createGetListString,
  createGetOneString,
  buildUpdateDynamoData,
  buildDeleteDynamoData,
  formatCreateResponse
};
