const pool = {
  "aws_project_region": "eu-west-2",
  "aws_cognito_region": "eu-west-2",
  "aws_user_pools_id": "eu-west-2_LGcrg5QdD",
  "aws_user_pools_web_client_id": "4gqglvrabio1qa8afgaiut0p1c",
  "federationTarget": "COGNITO_USER_POOLS"
}

let awsmobile = pool;

export default awsmobile;