import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
} from 'react-admin';

const WorkTitle = ({ record }) => {
    return <span>{record ? `"${record.title}"` : ''}</span>;
};

export const WorkList = props => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid rowClick="edit">
          <TextField source="title" />
          <TextField source="created_at" />
          <TextField source="summary" />
          <TextField source="link" />
      </Datagrid>
  </List>
);

export const WorkEdit = props => (
  <Edit title={<WorkTitle/>} {...props}>
      <SimpleForm>
          <TextInput source="title" />
          <TextInput source="summary" />
          <TextInput source="link" />
      </SimpleForm>
  </Edit>
);

export const WorkCreate = props => (
  <Create title={<WorkTitle/>} {...props}>
      <SimpleForm>
          <TextInput source="title" />
          <TextInput source="summary" />
          <TextInput source="link" />
      </SimpleForm>
  </Create>
);
