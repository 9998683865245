import * as React from "react";
import { Admin, Resource } from "react-admin";
import { BlogList, BlogEdit, BlogCreate } from "./Pages/Blogs";
import { VideoList, VideoEdit, VideoCreate } from "./Pages/Videos";
import { WorkList, WorkEdit, WorkCreate } from "./Pages/Works";
import Amplify from "aws-amplify";
import {AmplifyAuthenticator, AmplifySignOut, AmplifySignIn, AmplifyContainer} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsExports from './aws-exports';
import DataProvider from "./DataProvider";
import { useState, useEffect } from "react";

Amplify.configure(awsExports);

const App = () => {
  const [authState, setAuthState] = useState();
  const AUTH_STATE_LOCAL_STATE_KEY = "local-authstate";

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) =>{
      setAuthState(nextAuthState);

      localStorage.setItem(AUTH_STATE_LOCAL_STATE_KEY, nextAuthState);
    });
  });

  return authState === AuthState.SignedIn ? (
    <div>
      <Admin dataProvider={DataProvider}>
          <Resource name="blogs" list={BlogList} edit={BlogEdit} create={BlogCreate}/>
          <Resource name="videos" list={VideoList} edit={VideoEdit} create={VideoCreate}/>
          <Resource name="works" list={WorkList} edit={WorkEdit} create={WorkCreate}/>
      </Admin>
      <AmplifySignOut />
    </div>
  ) : (
    <AmplifyContainer>
        <AmplifyAuthenticator/>
    </AmplifyContainer>
  )
    
  
  
};
export default App;