import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
  BooleanField,
} from 'react-admin';

const VideoTitle = ({ record }) => {
    return <span>{record ? `"${record.title}"` : ''}</span>;
};

export const VideoList = props => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid rowClick="edit">
          <TextField source="title" />
          <TextField source="created_at" />
          <TextField source="url" />
          <BooleanField source="is_primary" />
      </Datagrid>
  </List>
);

export const VideoEdit = props => (
  <Edit title={<VideoTitle/>} {...props}>
      <SimpleForm>
          <TextInput source="title" />
          <TextInput source="url" />
          <BooleanInput source="is_primary" />
      </SimpleForm>
  </Edit>
);

export const VideoCreate = props => (
  <Create title={<VideoTitle/>} {...props}>
      <SimpleForm>
          <TextInput source="title" />
          <TextInput source="url" />
          <BooleanInput source="is_primary" />
      </SimpleForm>
  </Create>
);
