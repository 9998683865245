
const formatCreateResponse = (respData) => {
  return {
    "data": {
      "content": respData.content["S"],
      "created_at": respData.created_at["N"],
      "id": respData.id["S"],
      "title": respData.title["S"]
    }
  }
};

const buildCreateDynamoData = (params) => {
  return {
    "TableName": "blogs",
    "Item": {
      "title": {"S": params.data.title},
      "content": {"S": params.data.content},
      "created_at": {"N": `${+ new Date()}`},
      "generic_partition_key": {"S": "Blog"}
    }
  }
};

const buildUpdateDynamoData = (params) => {
  return {
    "TableName": "blogs",
    "Key": {
      "id":  {"S": params.id},
      "created_at": {"N": params.data.created_at_unix}
    },
    "UpdateExpression": "set title = :title, content = :content",
    "ExpressionAttributeValues": {
      ":title": {"S": params.data.title},
      ":content": {"S": params.data.content}
    }
  }
};

const buildDeleteDynamoData = (params) => {
  return {
    "TableName": "blogs",
    "Key": {
      "id":  {"S": params.id},
      "created_at": {"N": params.previousData.created_at_unix}
    }
  }
};

const createGetListString = (params) => {
  return `/read?table=blogs&page=${params.pagination.page}&perPage=${params.pagination.perPage}&type=getListPaginate&indexName=BlogGsi&partitionKey=generic_partition_key&paritionKeyType=S&partitionValue=Blog&scanIndexForward=false&sortField=${params.sort.field}&sortFieldKey=N&sortOrder=${params.sort.order}&filterValue=${params.filter.title}`
};


const createGetOneString = (params) => {
  return `/read?table=blogs&page=1&perPage=1&type=getListPaginate&partitionKey=id&paritionKeyType=S&partitionValue=${params.id}&scanIndexForward=false`;
};


const formatGetListResponse = (respData) => {
  let formattedResults = [];

  respData.results.forEach(result => {
    formattedResults.push({
      content: result.content['S'],
      created_at: new Date(parseInt(result.created_at['N'])).toLocaleString(),
      created_at_unix: result.created_at['N'],
      title: result.title['S'],
      id: result.id['S'],
    })
  });

  return {
    data: formattedResults,
    total: respData.totalItems
  }
};

module.exports = {
  formatCreateResponse,
  formatGetListResponse,
  buildCreateDynamoData,
  createGetListString,
  createGetOneString,
  buildUpdateDynamoData,
  buildDeleteDynamoData
};
