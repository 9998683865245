import { v4 as uuidv4 } from 'uuid';
import BlogFormatter from './Formatters/Blog';
import VideoFormatter from './Formatters/Video';
import WorkFormatter from './Formatters/Work';
import axios from "axios";


const DataProvider = {
  getList: (resource, params) => {
    let formatter;
    switch (resource) {
      case "blogs":
        formatter = BlogFormatter;
        break;
      case "videos":
        formatter = VideoFormatter;
        break;
      case "works":
        formatter = WorkFormatter;
        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      axios
      .get(`${process.env.REACT_APP_API_ROOT}` + formatter.createGetListString(params), {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
        }
      })
      .then(res => {
        resolve(formatter.formatGetListResponse(res.data));
      })
      .catch(err => {
        reject("Failure");
      });
    });
  },

  getOne: (resource, params) => {
    let formatter;
    switch (resource) {
      case "blogs":
        formatter = BlogFormatter;
        break;
      case "videos":
        formatter = VideoFormatter;
        break;
      case "works":
        formatter = WorkFormatter;
        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_ROOT}` + formatter.createGetOneString(params), {
          headers: {
            'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
          }
        })
        .then(res => {
          resolve({
            data: res.data.results[0]
          });
        })
        .catch(err => {
          reject("Failure");
        });
    });
  },

  getMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      resolve("Not implemented")
    });
  },

  getManyReference: (resource, params) => {
    return new Promise((resolve, reject) => {
      resolve("Not implemented")
    });
  },

  update: (resource, params) => {
    let formatter;
    switch (resource) {
      case "blogs":
        formatter = BlogFormatter;
        break;
      case "videos":
        formatter = VideoFormatter;
        break;
      case "works":
        formatter = WorkFormatter;
        break;
      default:
        break;
    }

    const dynamoData = formatter.buildUpdateDynamoData(params);
  
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_ROOT}/write`, {
            "Type": "update",
            dynamoData
          },
          {
            headers: {
              'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
            },
          }
          )
          .then(res => {
            resolve({data: res.data.Key});
          })
          .catch(err => {
            reject("Failure");
          });
    });
  },
      
  updateMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      resolve("Not implemented")
    });
  },

  create: (resource, params) => {
    let formatter;
    switch (resource) {
      case "blogs":
        formatter = BlogFormatter;
        break;
      case "videos":
        formatter = VideoFormatter;
        break;
      case "works":
        formatter = WorkFormatter;
        break;
      default:
        break;
    }

    const dynamoData = formatter.buildCreateDynamoData(params);
    dynamoData.Item.id = {"S": uuidv4()};

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_ROOT}/write`, {
            "Type": "create",
            dynamoData
          },
        {
          headers: {
            'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
          },
        }
        )
        .then(res => {
          resolve(formatter.formatCreateResponse(res.data.Item));
        })
        .catch(err => {
          reject("Failure");
        });
    });
  },

  delete: (resource, params) => {
    let formatter;
    switch (resource) {
      case "blogs":
        formatter = BlogFormatter;
        break;
      case "videos":
        formatter = VideoFormatter;
        break;
      case "works":
        formatter = WorkFormatter;
        break;
      default:
        break;
    }

    const dynamoData = formatter.buildDeleteDynamoData(params);

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_ROOT}/write`, {
            "Type": "delete",
            dynamoData
          },
        {
          headers: {
            'X-Api-Key': `${process.env.REACT_APP_API_KEY}`
          },
        }
        )
        .then(res => {
          resolve({data: res.data.Key});
        })
        .catch(err => {
          reject("Failure");
        });
    });
  },

  deleteMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      resolve("Not implemented")
    });
  },
};

export default DataProvider;