const formatVideoUrl = (url) => {
  return "https://www.youtube.com/embed/" + url.split("v=")[1];
};

const buildCreateDynamoData = (params) => {
  const is_primary = params.data.is_primary === true ? true : false;
  return {
    "TableName": "videos",
    "Item": {
      "title": {"S": params.data.title},
      "url": {"S": formatVideoUrl(params.data.url)},
      "is_primary": {"BOOL": is_primary},
      "created_at": {"N": `${+ new Date()}`},
      "generic_partition_key": {"S": "Video"}
    }
  }
};

const createGetListString = (params) => {
  return `/read?table=videos&page=${params.pagination.page}&perPage=${params.pagination.perPage}&type=getListPaginate&indexName=VideoGsi&partitionKey=generic_partition_key&paritionKeyType=S&partitionValue=Video&scanIndexForward=false&sortField=${params.sort.field}&sortFieldKey=N&sortOrder=${params.sort.order}&filterValue=${params.filter.title}`;
};

const createGetOneString = (params) => {
  return `/read?table=videos&page=1&perPage=1&type=getListPaginate&partitionKey=id&paritionKeyType=S&partitionValue=${params.id}&scanIndexForward=false`;
};

const buildUpdateDynamoData = (params) => {
  return {
    "TableName": "videos",
    "Key": {
      "id":  {"S": params.id},
      "created_at": {"N": params.data.created_at_unix}
    },
    "UpdateExpression": "set title = :title, #url = :url, #is_primary = :is_primary",
    "ExpressionAttributeNames": {
      "#url": "url",
      "#is_primary": "is_primary"
    },
    "ExpressionAttributeValues": {
      ":title": {"S": params.data.title},
      ":url": {"S": formatVideoUrl(params.data.url)},
      ":is_primary": {"BOOL": params.data.is_primary}
    }
  }
};

const buildDeleteDynamoData = (params) => {
  return {
    "TableName": "videos",
    "Key": {
      "id":  {"S": params.id},
      "created_at": {"N": params.previousData.created_at_unix}
    }
  }
};


const formatGetListResponse = (respData) => {
  let formattedResults = [];

  respData.results.forEach(result => {
    formattedResults.push({
      url: result.url['S'],
      created_at: new Date(parseInt(result.created_at['N'])).toLocaleString(),
      created_at_unix: result.created_at['N'],
      title: result.title['S'],
      is_primary: result.is_primary['BOOL'],
      id: result.id['S']
    })
  });

  return {
    data: formattedResults,
    total: respData.totalItems
  }
};

const formatCreateResponse = (respData) => {
  return {
    "data": {
      "url": respData.url["S"],
      "created_at": respData.created_at["N"],
      "is_primary": respData.is_primary["BOOL"],
      "id": respData.id["S"],
      "title": respData.title["S"]
    }
  }
};

module.exports = {
  formatGetListResponse,
  buildCreateDynamoData,
  createGetListString,
  createGetOneString,
  buildUpdateDynamoData,
  buildDeleteDynamoData,
  formatCreateResponse
};
